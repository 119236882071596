// src/data/predictioncarData.js

export const brandsData = {
    Acura: ['CL'],
    Audi: ['A8', 'Q7', 'A7', 'A3', 'Q5', 'A4', 'A6'],
    BMW: [
      'X4',
      '6-сери',
      'X6',
      '7-сери',
      '4-сери',
      'X1',
      '1-сери',
      'X5',
      '5-сери',
      'X3',
      '3-сери',
      'M-сери',
      'X7'
    ],
    BYD: ['Tang', 'Dolphin', 'Seal (Atto 4)', 'Han'],
    Baic: ['X3', 'BJ40'],
    Bestune: ['B70'],
    Cadillac: ['Escalade', 'CTS'],
    Changan: ['CS', 'UNI-T', 'UNI-K'],
    Chery: ['Tiggo 7 PRO', 'Tiggo 8', 'Tiggo 8 PRO MAX'],
    Chevrolet: [
      'Corvette',
      'TrailBlazer',
      'Colorado',
      'Malibu',
      'Traverse',
      'Captiva Sport',
      'Cruze',
      'Camaro',
      'Tahoe'
    ],
    Chrysler: ['Crossfire', '300C'],
    Daihatsu: ['YRV', 'Hijet'],
    Dodge: ['Durango', 'Challenger', 'Ram'],
    Dongfeng: ['Aeolus Huge', 'Voyah Free', 'Forthing T5 Evo'],
    Ford: [
      'Mustang',
      'E-Сери',
      'Ranger',
      'Transit',
      'Explorer',
      'Pickup',
      'Escape',
      'Excursion',
      'F-сери',
      'Everest',
      'Expedition'
    ],
    Foton: ['View CS2', 'Tunland'],
    GWM: ['Tank Tank 300', 'Tank'],
    Geely: ['Azkarra', 'Tugella', 'Monjaro', 'Coolray'],
    Haval: ['Dargo 4x2', 'M-Series', 'H-Series', 'F-Series'],
    Honda: [
      'Civic',
      'CR-Z',
      'Crossroad',
      'Insight',
      'Pilot',
      'CR-V',
      'Odyssey',
      'Stepwgn',
      'HR-V',
      'Fit'
    ],
    Hummer: ['H-3', 'H-2', 'H-1'],
    Hyundai: [
      'Starex',
      'Avante',
      'Sonata',
      'County',
      'Tucson',
      'Santa-Fe',
      'Grandeur',
      'Genesis',
      'Azera',
      'Sonata Hybrid',
      'Accent',
      'Elantra',
      'Equus',
      'Galloper',
      'Grace',
      'Terracan',
      'Veracruz'
    ],
    Infiniti: ['EX', 'G', 'Q', 'FX', 'QX'],
    Jaguar: ['X-Type', 'XJ'],
    Jeep: ['Grand Cherokee', 'Wrangler', 'Cherokee'],
    Jetour: ['X90 Plus', 'Dashing'],
    Kaiyi: ['X Series'],
    Kia: ['Sorento', 'Cadenza/K7', 'Forte', 'Sportage', 'Optima/K5', 'Rexton'],
    Lada: ['Granta', 'Niva', 'Vesta'],
    'Land Rover': [
      'Range Rover',
      'Discovery',
      'Бусад',
      'Defender',
      'Freelander',
      'Land Rover'
    ],
    Lexus: ['LS', 'IS', 'NX', 'LX', 'GX', 'HS', 'GS', 'ES', 'CT', 'LFA', 'RX'],
    Li: ['Auto One', 'Auto L8', 'Auto L9', 'Auto L7'],
    MINI: ['Countryman', 'Cooper'],
    Mazda: [
      'Mazda2 Demio',
      'CX',
      'Miata MX-5',
      'Mazda6 Atenza',
      '626 Capella',
      'Bongo',
      'Pickup',
      'Verisa',
      'Mazda3 Axela',
      'RX'
    ],
    Mazda6: ['Atenza'],
    'Mercedes-Benz': [
      'M-Class',
      'CL-Class',
      'Sprinter',
      'CLK-Class',
      'GLA-Class',
      'SLS AMG',
      'SLK-Class',
      'E-Class',
      'GL-Class',
      'CLS-Class',
      'CLA-Class',
      'A-Class',
      'B-Class',
      'SL-Class',
      'G-Class',
      'GLK-Class',
      'C-Class',
      'S-Class'
    ],
    Mitsubishi: [
      'Delica',
      'Pajero',
      'Montero',
      'L200',
      'RVR',
      'Lancer',
      'Airtrek',
      'Challenger',
      'Eclipse',
      'Pickup',
      'Colt',
      'Galant',
      'Outlander'
    ],
    Neta: ['Neta U'],
    Nissan: [
      'Caravan',
      'Elgrand',
      '240SX',
      'Cube',
      'GT-R',
      'NV Passenger',
      'Note',
      'Altima',
      'March',
      'Leaf',
      'Navara',
      'Frontier',
      'Dualis/Qashqai',
      'Fuga',
      'Advan',
      'Vanette',
      'Versa',
      'Datsun',
      'Presage',
      'Tiida',
      'Patrol',
      'Sylphy',
      'Skyline',
      'Teana',
      'NV200',
      'Xterra',
      'Serena',
      'Pickup',
      'Qashqai',
      'Armada',
      'Wingroad',
      'Juke',
      'Murano',
      '200SX',
      'Pathfinder',
      'X-Trail',
      'Pulsar'
    ],
    Porsche: ['Cayman', 'Cayenne', '911', 'Macan', 'Boxster', 'Panamera'],
    Prius: ['20'],
    SAIC: ['LDV T60'],
    Samsung: ['SM', 'QM'],
    SsangYong: ['Actyon', 'Korando', 'Musso', 'Rodius Stavic', 'Istana', 'Rexton'],
    Subaru: [
      'Legacy',
      'Forester',
      'WRX STI',
      'WRX',
      'XV Crosstrek',
      'Liberty',
      'Impreza',
      'Trezia',
      'Exiga',
      'Outback',
      'XT'
    ],
    Suzuki: ['Swift', 'Grand Vitara', 'Jimny', 'SX4', 'Escudo', 'Vitara', 'XL7', 'Hustler'],
    Tesla: ['Model Y', 'Model S'],
    Tota: ['Rumion'],
    Toyota: [
      'Estima',
      'Vanguard',
      'Chaser',
      'Vellfire',
      'Passo',
      'Corolla',
      'Land Cruiser 77',
      'Avensis',
      'Succeed',
      'Prius 51',
      'Land Cruiser Prado',
      'Kluger',
      'Sai',
      '4Runner',
      'Ist',
      'Prius 30',
      'Hiace',
      'Highlander',
      'Land Cruiser Prado 120',
      'Mark',
      'Avalon',
      'Tacoma',
      'Land Cruiser 105',
      'Land cruiser Prado 150',
      'Prius PRIME 52',
      'Land cruiser Prado 95',
      'Esquire',
      'Spade Porte',
      'Harrier',
      'Hilux',
      'FJ Cruiser',
      'Raum',
      'Premio',
      'Noah',
      'Prius 50',
      'Prius 55',
      'Land cruiser Prado 250',
      'Aqua',
      'Crown Kluger',
      'Wildlander',
      'Altezza',
      'Sequoia',
      'Land Cruiser 200',
      'Fielder',
      'Yaris',
      'Rumion',
      'Granvia',
      'Ractis',
      'Fortuner',
      'C-HR',
      'Land Cruiser 300',
      'Cresta',
      'Wish',
      'Sienta',
      'bB',
      'Rush',
      'Isis',
      'Voxy',
      'Land cruiser Prado 90',
      'Prius 20',
      'Allion',
      'Crown',
      'Corolla cross',
      'Land cruiser Prado 70',
      'Land Cruiser 100',
      'Brevis',
      'Alphard',
      'Tundra',
      'Sienna',
      'Land Cruiser 70',
      'Axio',
      'Camry',
      'Auris',
      'Land Cruiser Prado 150',
      'Ipsum',
      'Prius 35',
      'Probox',
      'Land cruiser Prado 120',
      'Townace',
      'Land Cruiser 80',
      'Prius 41',
      'Vitz',
      'Venza',
      'RAV4',
      'Prius 40'
    ],
    Volkswagen: ['Golf', 'Tiguan', 'Beetle', 'CC', 'Jetta', 'Polo', 'Up', 'Touareg', 'Passat', 'Scirocco'],
    Volvo: ['S60', 'XC60', 'V40', 'XC90']
  };
  