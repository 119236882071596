// src/components/ConfirmPasswordReset.js

import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useParams, useNavigate } from 'react-router-dom';
import { TextField, Button, Typography, Container, Box, Alert, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

export default function ConfirmPasswordReset() {
  const { token } = useParams();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const { confirmResetPassword } = useAuth();
  const navigate = useNavigate();

  const [passwordCriteria, setPasswordCriteria] = useState({
    length: false,
    letter: false,
    number: false,
    specialChar: false,
  });

  useEffect(() => {
    if (!token) {
      setError('Invalid or missing reset token. Please try resetting your password again.');
    }
  }, [token]);

  const validatePassword = (pwd) => {
    const criteria = {
      length: pwd.length >= 8,
      letter: /[A-Za-z]/.test(pwd),
      number: /\d/.test(pwd),
      specialChar: /[_!@#$%^&*(),.?":{}|<>]/.test(pwd),
    };
    setPasswordCriteria(criteria);
    return Object.values(criteria).every(Boolean);
  };

  const handlePasswordChange = (e) => {
    const pwd = e.target.value;
    setNewPassword(pwd);
    validatePassword(pwd);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  async function handleSubmit(e) {
    e.preventDefault();
    setError('');
    setMessage('');

    if (!validatePassword(newPassword)) {
      setError('Please ensure your password meets all the requirements.');
      return;
    }

    if (newPassword !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    try {
      await confirmResetPassword(token, newPassword);
      setMessage('Нууц үг амжилттай шинэчлэгдлээ. Та одоо нэвтэрч болно.');
      setTimeout(() => {
        navigate('/login');
      }, 3000);
    } catch (err) {
      setError(err.message || 'Нууц үг шинэчлэх үйл явц амжилтгүй боллоо.');
    }
  }

  const renderCriteria = () => (
    <Box sx={{ width: '100%', mt: 1 }}>
      <Typography variant="subtitle2">Password must contain:</Typography>
      <List dense>
        <ListItem>
          <ListItemIcon>
            {passwordCriteria.length ? <CheckIcon color="success" /> : <CloseIcon color="error" />}
          </ListItemIcon>
          <ListItemText primary="At least 8 characters" />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            {passwordCriteria.letter ? <CheckIcon color="success" /> : <CloseIcon color="error" />}
          </ListItemIcon>
          <ListItemText primary="At least one letter" />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            {passwordCriteria.number ? <CheckIcon color="success" /> : <CloseIcon color="error" />}
          </ListItemIcon>
          <ListItemText primary="At least one number" />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            {passwordCriteria.specialChar ? <CheckIcon color="success" /> : <CloseIcon color="error" />}
          </ListItemIcon>
          <ListItemText primary='At least one special character (_!@#$%^&*(),.?":{}|<>)' />
        </ListItem>
      </List>
    </Box>
  );

  if (!token) {
    return (
      <Container component="main" maxWidth="xs">
        <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography component="h1" variant="h5">
            Invalid Reset Link
          </Typography>
          <Typography sx={{ mt: 2 }}>
            The password reset link is invalid or has expired. Please try resetting your password again.
          </Typography>
        </Box>
      </Container>
    );
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography component="h1" variant="h5">
          Нууц үг шинэчлэх
        </Typography>
        {error && <Alert severity="error" sx={{ width: '100%', mt: 2 }}>{error}</Alert>}
        {message && <Alert severity="success" sx={{ width: '100%', mt: 2 }}>{message}</Alert>}
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            name="newPassword"
            label="Шинэ Нууц Үг"
            type="password"
            id="newPassword"
            autoComplete="new-password"
            value={newPassword}
            onChange={handlePasswordChange}
            disabled={!!message}
          />
          {renderCriteria()}
          <TextField
            margin="normal"
            required
            fullWidth
            name="confirmPassword"
            label="Нууц Үг Баталгаажуулах"
            type="password"
            id="confirmPassword"
            autoComplete="new-password"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            disabled={!!message}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={
              !newPassword ||
              !confirmPassword ||
              !Object.values(passwordCriteria).every(Boolean) ||
              newPassword !== confirmPassword ||
              !!message
            }
          >
            Шинэчлэх
          </Button>
        </Box>
      </Box>
    </Container>
  );
}