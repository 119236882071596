// src/components/CarDetail.js

import React, { useState, useEffect, useMemo } from 'react';
import { useParams, Link, useLocation, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import {
  Typography, Box, Grid, Button, Table, TableBody, TableCell, TableContainer, TableRow, Paper, Chip, IconButton, TextField, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, InputAdornment
} from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useAuth } from '../contexts/AuthContext';
import RecommendedCars from './RecommendedCars';
import { getCarListings, saveCarListing, incrementCarViewCount, createOrGetChat, getCarListingById } from '../utils/db';
import { getTimeSincePosted } from '../utils/timeUtils';
import '../styles/CarDetail.css';
import ContactSellerForm from './ContactSellerForm';
import { updateCarSaveCount, deleteCarListing, getRecommendedCars } from '../utils/db';
import { api } from '../contexts/AuthContext';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

// Create a custom theme
const theme = createTheme({
  typography: {
    fontFamily: '"Rubik", "Noto Sans Mongolian", sans-serif',
  },
  palette: {
    primary: {
      main: '#000000',
    },
    text: {
      primary: '#000000',
    },
  },
});

const ImageContainer = styled(Box)({
  position: 'relative',
  width: '100%',
  paddingTop: '56.25%', // 16:9 aspect ratio
  borderRadius: '16px',
  overflow: 'hidden',
  '@media (max-width: 600px)': {
    borderRadius: '0',
    paddingTop: '75%', // 4:3 aspect ratio for mobile
  },
});

const CarImage = styled('img')({
  position: 'absolute',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  objectFit: 'contain', // Changed from 'cover' to 'contain'
  backgroundColor: '#f0f0f0', // Light grey background
});

const ArrowButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  backgroundColor: 'rgba(255, 255, 255, 0.7)',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
  },
}));

const Root = styled('div')(({ theme }) => ({
  maxWidth: 1200,
  margin: '0 auto',
  padding: theme.spacing(2),
  fontFamily: 'Rubik',
  '@media (max-width: 600px)': {
    padding: 0,
  },
}));

const SaveButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(2),
  backgroundColor: 'white',
  color: 'black',
  '&:hover': {
    backgroundColor: 'black',
    color: 'white',
  },
  boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
  fontWeight: 'bold',
  padding: theme.spacing(1, 2),
  textTransform: 'none',
}));

const BreadcrumbNav = styled('nav')(({ theme }) => ({
  marginBottom: theme.spacing(2),
  '& a': {
    color: 'inherit',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const MainImage = styled('img')({
  width: '100%',
  height: 'auto',
});

const ThumbnailContainer = styled(Box)({
  display: 'flex',
  overflowX: 'auto',
  marginTop: '10px',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  scrollbarWidth: 'none',
});

const Thumbnail = styled('img')({
  width: '60px',
  height: '60px',
  objectFit: 'cover',
  marginRight: '10px',
  cursor: 'pointer',
  borderRadius: '8px',
});

const Price = styled(Typography)(({ theme }) => ({
  fontSize: '2.5rem',
  fontWeight: 'bold',
  marginTop: theme.spacing(2),
}));

const BasicsTable = styled(TableContainer)(({ theme }) => ({
  '& .MuiTableCell-root': {
    borderBottom: '1px solid #e0e0e0',
    padding: theme.spacing(1, 0),
    fontFamily: '"Rubik", "Noto Sans Mongolian", sans-serif',
  },
  '& .MuiTableCell-body': {
    fontSize: '0.9rem',
  },
}));

const BasicsLabel = styled(TableCell)(({ theme }) => ({
  fontWeight: 'bold',
  width: '30%',
  fontFamily: '"Rubik", "Noto Sans Mongolian", sans-serif',
}));

const BasicsValue = styled(TableCell)({
  color: '#555',
  fontFamily: '"Rubik", "Noto Sans Mongolian", sans-serif',
});

const ChipContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(2),
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
  borderRadius: '16px',
  fontWeight: 400,
  color: 'black',
  borderColor: 'black'
}));

const SellerLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.primary.main,
  fontWeight: 'bold',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

const SellerInfoContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  width: '100%',
  fontSize: '1.3em',
}));

const SellerInfoItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
}));

const BasicsContainer = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

const DescriptionBox = styled(Box)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
  padding: '10px',
  border: '1px solid #e0e0e0',
  borderRadius: '4px',
}));

const DescriptionText = styled(Typography)(({ theme }) => ({
  fontFamily: '"Rubik", "Noto Sans Mongolian", sans-serif',
  whiteSpace: 'pre-wrap',
  wordWrap: 'break-word',
}));

const InfoSection = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  height: '100%',
}));

const InfoItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(1, 0),
  '&:last-child': {
    borderBottom: 'none',
  },
}));

const InfoLabel = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.text.secondary,
}));

const InfoValue = styled(Typography)(({ theme }) => ({
  textAlign: 'right',
}));

function CarDetail() {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [car, setCar] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isSaved, setIsSaved] = useState(false);
  const { currentUser } = useAuth(); 
  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [editedCar, setEditedCar] = useState(null);
  const [recommendedCars, setRecommendedCars] = useState([]);
  const [isContactFormOpen, setIsContactFormOpen] = useState(false);
  const [error, setError] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isPriceEditing, setIsPriceEditing] = useState(false);
  const isOwner = useMemo(() => {
    return currentUser && car && currentUser.id === car.owner;
  }, [currentUser, car]);

  useEffect(() => {
    const fetchCarData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        // Fetch the car data directly using its ID
        const carData = await getCarListingById(id);
        console.log("API response:", carData);

        const processedCar = {
          ...carData,
          images: carData.images.map(image => 
            image.image.startsWith('http') 
              ? image.image 
              : `${process.env.REACT_APP_API_URL}${image.image}`
          )
        };
        
        setCar(processedCar);
        setEditedCar(processedCar);

        // Fetch all listings for recommended cars
        const recommended = await getRecommendedCars(id);
        setRecommendedCars(recommended);

        // Increment view count and handle saved cars...

        const lastViewTime = localStorage.getItem(`lastView_${id}`);
        const currentTime = new Date().getTime();
        if (!lastViewTime || (currentTime - parseInt(lastViewTime)) > 3600000) { // 1 hour cooldown
          try {
            const updatedViews = await incrementCarViewCount(id);
            if (updatedViews) {
              setCar(prevCar => ({
                ...prevCar,
                views: updatedViews.views
              }));
              // Set the last view time after successful increment
              localStorage.setItem(`lastView_${id}`, currentTime.toString());
            }
          } catch (error) {
            console.error('Error incrementing view count:', error);
            // Don't set an error state here, just log it
          }
        }

        // Check if the car is saved
        const savedCars = JSON.parse(localStorage.getItem('savedCars')) || {};
        setIsSaved(!!savedCars[id]);

      } catch (error) {
        console.error('Error fetching car data:', error);
        setError('Failed to fetch car data: ' + error.message);
      }
      setIsLoading(false);
    };

    fetchCarData();
  }, [id, currentUser]);


  const handleUpdate = async () => {
    try {
      console.log('Current user:', currentUser);
      console.log('Listing owner:', car.owner);
      console.log('Edited car data:', editedCar);
      
      // Create a FormData object with only the price
      const formData = new FormData();
      formData.append('price', editedCar.price);

      // Send a PATCH request to update only the price
      const response = await api.patch(`/listings/${car.id}/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      
      console.log('Update response:', response);
      
      setCar(response.data);
      setIsPriceEditing(false);
    } catch (error) {
      console.error('Error updating car listing:', error.response ? error.response.data : error.message);
      console.log('Full error object:', error);
      setError('Failed to update car listing: ' + (error.response?.data?.error || error.message));
    }
  };


  const handlePriceEdit = () => {
    setIsPriceEditing(true);
  };

  const handleDeleteClick = () => {
    setIsDeleteDialogOpen(true);
  };


  const handleDeleteConfirm = async () => {
    try {
      await deleteCarListing(car.id);
      navigate('/listings');
    } catch (error) {
      console.error('Error deleting car listing:', error);
      setError('Failed to delete listing');
    }
    setIsDeleteDialogOpen(false);
  };

  const handleDeleteCancel = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedCar(prev => ({ ...prev, [name]: value }));
  };
  
  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = async () => {
    if (!currentUser) {
      navigate('/login', { state: { from: location } });
      return;
    }

    const newIsSaved = !isSaved;
    setIsSaved(newIsSaved);

    const savedCars = JSON.parse(localStorage.getItem('savedCars')) || {};
    if (newIsSaved) {
      savedCars[id] = true;
    } else {
      delete savedCars[id];
    }
    localStorage.setItem('savedCars', JSON.stringify(savedCars));

    try {
      // Update the save count on the backend
      await updateCarSaveCount(car.id, newIsSaved);
    } catch (error) {
      console.error('Error updating save count:', error);
      // Optionally, revert the local state if the backend update fails
      setIsSaved(!newIsSaved);
    }
  };

  const handleOpenContactForm = () => {
    if (!currentUser) {
      navigate('/login', { state: { from: location } });
      return;
    }
    setIsContactFormOpen(true);
  };

  const handleCloseContactForm = () => {
    setIsContactFormOpen(false);
  };
  
  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) => 
      prevIndex === 0 ? car.images.length - 1 : prevIndex - 1
    );
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => 
      prevIndex === car.images.length - 1 ? 0 : prevIndex + 1
    );
  }



  const handleContactSeller = async () => {
    if (!currentUser) {
      navigate('/login', { state: { from: location } });
      return;
    }
    try {
      const chat = await createOrGetChat(car.owner);
      navigate('/chat', { state: { selectedChat: chat } });
    } catch (error) {
      console.error('Error starting chat:', error);
    }
  };

  if (isLoading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;
  if (!car) return <Typography>Car not found</Typography>;

  return (
    <ThemeProvider theme={theme}>
      <Root className='car-detail-page'>
        <BreadcrumbNav>
          <Typography variant="body2">
            <Link to="/">Home</Link> / 
            <Link to="/listings" state={{ filters: location.state?.filters }}>
              Cars for Sale
            </Link> / 
            {car.yearMade} {car.make} {car.model} {car.trim}
          </Typography>
        </BreadcrumbNav>
    
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ImageContainer>
              <CarImage src={car.images[currentImageIndex]} alt={`${car.make} ${car.model}`} />
              <ArrowButton onClick={handlePrevImage} sx={{ left: 10 }}>
                <ArrowBackIosNewIcon />
              </ArrowButton>
              <ArrowButton onClick={handleNextImage} sx={{ right: 10 }}>
                <ArrowForwardIosIcon />
              </ArrowButton>
              <SaveButton
                variant="contained"
                startIcon={isSaved ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                onClick={handleSave}
              >
                {isSaved ? 'Хадгалсан' : 'Хадгалах'}
              </SaveButton>
            </ImageContainer>
            <ThumbnailContainer>
              {car.images.map((image, index) => (
                <Thumbnail
                  key={index}
                  src={image}
                  alt={`${car.make} ${car.model} - ${index + 1}`}
                  onClick={() => setCurrentImageIndex(index)}
                  style={{
                    border: index === currentImageIndex ? '2px solid #0052FF' : 'none',
                  }}
                />
              ))}
            </ThumbnailContainer>
          </Grid>
    
          <Grid item xs={12}>
            <Box display="flex" alignItems="center" justifyContent="space-between" flexWrap="wrap">
              <Typography variant="h4" component="h1" style={{ fontWeight: 'bold', marginBottom: '10px' }}>
                {car.yearMade} {car.make} {car.model} {car.trim}
              </Typography>
              <Typography variant="body2" style={{ color: 'grey' }}>
                ({getTimeSincePosted(car.datePosted)})
              </Typography>
            </Box>
    
            <ChipContainer>
              {car.odometer && <StyledChip label={`${car.odometer} км`} />}
              {car.yearMade && <StyledChip label={`${car.yearMade}`} />}
              {car.yearImported && <StyledChip label={`${car.yearImported}`} />}
              {car.gearbox && <StyledChip label={car.gearbox} />}
              {car.steering && <StyledChip label={car.steering} />}
              {car.willBeShipped && (
                <StyledChip 
                  icon={<LocalShippingIcon />}
                  label="Гадаадаас ачигдана"
                  color="primary"
                />
              )}
            </ChipContainer>
    
            {isPriceEditing ? (
              <Box mt={2}>
                <TextField
                  fullWidth
                  margin="normal"
                  name="price"
                  label="Үнэ"
                  value={editedCar.price}
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^\d]/g, '');
                    setEditedCar(prev => ({
                      ...prev,
                      price: value === '' ? '' : parseInt(value, 10)
                    }));
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">₮</InputAdornment>,
                  }}
                />
                <Button variant="contained" color="primary" onClick={handleUpdate} style={{ marginTop: '1rem', marginRight: '1rem' }}>
                  Засаж дууссан
                </Button>
                <Button variant="outlined" color="secondary" onClick={() => setIsPriceEditing(false)} style={{ marginTop: '1rem' }}>
                  Цуцлах
                </Button>
              </Box>
            ) : (
              <>
                <Price>₮{Number(car.price || 0).toLocaleString()}</Price>
                {isOwner && (
                  <Box mt={2}>
                    <Button variant="contained" color="primary" onClick={handlePriceEdit} style={{ marginRight: '1rem' }}>
                      Үнийн Өөрчлөлт Оруулах
                    </Button>
                    <Button variant="contained" color="error" onClick={handleDeleteClick}>
                      Устгах
                    </Button>
                  </Box>
                )}
              </>
            )}
          </Grid>
    
          <Grid item xs={12}>
            <SellerInfoContainer>
              <SellerLink to={`/seller/${car.owner}`}>
                <Typography 
                  variant="h6" 
                  gutterBottom 
                  style={{ 
                    fontSize: '1.4em', 
                    borderBottom: '1px solid',
                    display: 'inline-block',
                    marginBottom: '15px'
                  }}
                >
                  {car.ownerUsername}
                </Typography>
              </SellerLink>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <SellerInfoItem>
                    <PhoneIcon fontSize="small" style={{ marginRight: '10px' }} />
                    <Typography variant="body2">{car.sellerPhone || 'Not provided'}</Typography>
                  </SellerInfoItem>
                  <SellerInfoItem>
                    <LocationOnIcon fontSize="small" style={{ marginRight: '10px' }} />
                    <Typography variant="body2">{car.sellerLocation || 'Not provided'}</Typography>
                  </SellerInfoItem>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SellerInfoItem>
                    <VisibilityIcon fontSize="small" style={{ marginRight: '10px' }} />
                    <Typography variant="body2">{car.views || 0} үзсэн • {car.saves || 0} хадгалсан</Typography>
                  </SellerInfoItem>
                  <SellerInfoItem>
                    <SwapHorizIcon fontSize="small" style={{ marginRight: '10px' }} />
                    <Typography variant="body2">
                      {car.tradeIn === 'yes' ? 'Машин Оролцуулна' : 'Машин Оролцуулахгүй'}
                    </Typography>
                  </SellerInfoItem>
                </Grid>
              </Grid>
              {!isOwner && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpenContactForm}
                  fullWidth
                  sx={{ mt: 2, color: 'white', backgroundColor: 'black', '&:hover': { backgroundColor: '#333' } }}
                >
                  Үнийн санал тавих
                </Button>
              )}
            </SellerInfoContainer>
          </Grid>
    
          <Grid item xs={12} md={6}>
            <InfoSection>
              <Typography variant="h6" gutterBottom>Нэмэлт Мэдээлэл</Typography>
              <InfoItem>
                <InfoLabel>Гадна өнгө</InfoLabel>
                <InfoValue>{car.exteriorColor}</InfoValue>
              </InfoItem>
              <InfoItem>
                <InfoLabel>Дотор өнгө</InfoLabel>
                <InfoValue>{car.interiorColor}</InfoValue>
              </InfoItem>
              <InfoItem>
                <InfoLabel>Хөтлөгч</InfoLabel>
                <InfoValue>{car.drivetrain}</InfoValue>
              </InfoItem>
              <InfoItem>
                <InfoLabel>Шатахуун зарцуулалт</InfoLabel>
                <InfoValue>
                  {car.mpgCity} л/100км (хот дотор)<br />
                  {car.mpgHighway} л/100км (хот гадна)
                </InfoValue>
              </InfoItem>
              <InfoItem>
                <InfoLabel>Шатахуун</InfoLabel>
                <InfoValue>{car.fuelType}</InfoValue>
              </InfoItem>
              <InfoItem>
                <InfoLabel>Суудлын тоо</InfoLabel>
                <InfoValue>{car.seats}</InfoValue>
              </InfoItem>
              <InfoItem>
                <InfoLabel>Хурдны хайрцаг</InfoLabel>
                <InfoValue>{car.gearbox}</InfoValue>
              </InfoItem>
              <InfoItem>
                <InfoLabel>Хүрд</InfoLabel>
                <InfoValue>{car.steering}</InfoValue>
              </InfoItem>
              <InfoItem>
                <InfoLabel>Хөдөлгүүр</InfoLabel>
                <InfoValue>{car.engine}</InfoValue>
              </InfoItem>
              <InfoItem>
                <InfoLabel>Арлын дугаар</InfoLabel>
                <InfoValue>{car.vin}</InfoValue>
              </InfoItem>
              <InfoItem>
                <InfoLabel>Гүйлт</InfoLabel>
                <InfoValue>{car.odometer} км</InfoValue>
              </InfoItem>
            </InfoSection>
          </Grid>
    
          <Grid item xs={12} md={6}>
            <InfoSection>
              <Typography variant="h6" gutterBottom>Нэмэлт Тайлбар</Typography>
              <Box sx={{ mt: 2 }}>
                <Typography variant="body1" style={{ whiteSpace: 'pre-wrap' }}>
                  {car.description || "Нэмэлт тайлбар байхгүй байна."}
                </Typography>
              </Box>
            </InfoSection>
          </Grid>
  
          <Grid item xs={12}>
           <RecommendedCars recommendedCars={recommendedCars} />
          </Grid>
        </Grid>
  
        <ContactSellerForm
          open={isContactFormOpen}
          onClose={handleCloseContactForm}
          car={car}
        />
  
        <Dialog
          open={isDeleteDialogOpen}
          onClose={handleDeleteCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Устгахдаа итгэлтэй байна"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Та зараа устгахдаа итгэлтэй байна уу? Та нэмэлт эрх худалдаж аваагүй бол танд энэ зар тавигдсанаас 30 хоногийн дараа дахин зар тавих эрх нээгдэнэ.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteCancel} color="primary">
              Буцах
            </Button>
            <Button onClick={handleDeleteConfirm} color="error" autoFocus>
              Устгах
            </Button>
          </DialogActions>
        </Dialog>
      </Root>
    </ThemeProvider>
  );
}

export default CarDetail;