// src/data/carData.js

export const carData = {
    "Acura": ["CL", "Integra", "ILX", "MDX", "RDX", "RLX", "RSX", "TL", "TLX", "TSX", "ZDX", "NSX", "Бусад" /* ... */],
    "Alfa Romeo": ["4C", "4C Spider", "Giulia", "Spider", "Stelvio", "Бусад" /* ... */],
    "Aston Martin": ["DB11", "DB12", "DB9", "DB9GT", "DBS", "DBS Superleggera", "DBX", "Rapide", "Vanquish", "Vanquish S", "Vantage", "Virage", "Бусад"], 
    "Audi": ["A3", "A3 Sportback e-tron", "A4", "A5", "A6", "A6 e-tron", "A7", "A8", "e-tron", "Q3", "Q4 e-tron", "Q5", "Q7", "Q8", "Quattro", "R8", "RS 3", "RS 4", "RS 5", "RS 6", "RS 7", "RS e-tron GT", "RS Q8", "S3", "S4", "S5", "S6",  "S7", "S8", "SQ5", "SQ7", "SQ8", "TT", "Бусад"/* ... */],
    "Bentley": ["Arnage", "Azure", "Bentayga", "Brooklands", "Continental", "Continental GT", "Flying Spur", "Mulsanne", "Бусад"],
    "BMW": ["1 Series", "2 Series", "3 Series", "4 Series", "5 Series", "6 Series", "7 Series", "i3", "i4", "i7", "i8", "iX", "M", "M2", "M3", "M4", "M5", "M8", "X1", "X3", "X5", "X7", "XM", "Z3", "Z4", "Z8", "Бусад"/* ... */],
    "Bestune": ["B70", "M9", "Бусад"],
    "BYD": ["Atto 3", "e6", "Dolphin", "B Series", "Han", "F3", "Seal", "Seagull", "Song Plus", "Tang", "Бусад"],
    "Buick": ["Cascada", "Century", "Enclave", "Encore", "Envision", "LaCrosse", "LeSabre", "Lucerne", "Park Avenue", "Rainier", "Regal", "Rendezvous", "Riviera", "Roadmaster", "Skylark", "Terraza", "Verano", "Бусад"],
    "Cadillac": ["Allante", "ATS", "Catera", "CT4", "CT5", "CT6", "CTS", "DTS", "ELR", "Escalade", "LYRIQ", "SRX", "STS", "XT4", "XT5", "Бусад"],
    "Changan": ["Alsvin", "CS35 Plus", "CS55", "CS75", "CS85", "Hunter", "Kuayue", "EADOplus", "Shenlan", "Uni-k", "Oshan X7", "Lumin", "Бусад"],
    "Chevrolet": ["Avalance", "Aveo", "Blazer", "Bolt", "Camaro", "Captiva", "City Express", "Colorado", "Corvette", "Cruze", "Equinox", "Express", "HHR", "Impala", "Malibu", "Silverado", "Sonic", "Spark", "Suburban", "Tahoe", "Trailblazer", "Trax", "Volt", "Бусад"],
    "Chrysler": ["200", "300", "Crossfire", "Pacifica", "Sebring", "Town & Country", "Voyager", "Бусад"],
    "Daewoo": ["Lanos", "Leganza", "Nubira", "Бусад"],
    "Daihatsu": ["Charade", "Rocky", "Бусад"],
    "Dodge": ["Avenger", "Caliber", "Caravan", "Challenger", "Charger", "Dakota", "Dart", "Durango", "Hornet", "Ram", "Sprinter", "Viper", "Бусад"],
    "Dongfeng": ["Aeolus Huge", "Aeoulus Shine", "Forthing T5 Evo", "Forthing Yacht", "Voyah Free", "Voyah Dreamer", "Nammi", "Paladin", "Бусад"],
    "Ferrari": ["296", "430 Scuderia", "458", "488", "599", "612", "812", "California", "F12berlinetta", "F430", "F8", "FF", "GTC4Lusso", "Portofino", "Roma", "SF90", "Purosangue", "SF90", "Бусад"],
    "FIAT": ["500", "500 Abarth", "500c", "500c Abarth", "500e", "500L", "500X", "Бусад"],
    "Fisker": ["Karma", "Ocean", "Бусад"],
    "Ford": ["Bronco", "C-MAX", "Crown Victoria", "E-Transit 350 Cargo Van", "E150", "E250", "E350", "EcoSport", "Edge", "Escape", "Expedition", "Explorer", "F150", "F150 Lightning", "F250", "F350", "F450", "Flex", "Focus", "Fusion", "Maverick", "Mustang", "Ranger", "Taurus", "Transit", "Бусад"],
    "Foton": ["Tunland", "View CS2", "Jaguar", "Бусад"],
    "Geely": ["Azkarra", "Coolray", "Emgrand", "Geometry C", "Monjaro", "Preface", "Okavango", "Tugella", "Starray", "Бусад"],
    "Genesis": ["G70", "G80", "G90", "GV60", "GV70", "GV80", "Бусад"],
    "GMC": ["Acadia", "Canyon", "HUMMER EV", "Savana", "Sierra", "Suburban", "Yukon", "Бусад"],
    "GWM": ["Tank", "Бусад"],
    "Haval": ["Dargo 4x2", "F-Series", "H-Series", "M-Series", "Jolion", "Бусад"],
    "Honda": ["Accord", "Civic", "Civic Type R", "CR-V", "Clarity", "CRZ", "Crosstour", "Element", "Fit", "HR-V", "Insight", "Odyssey", "Passport", "Pilot", "Prelude", "Ridgeline", "S2000", "Бусад"],
    "HUMMER": ["H1", "H2", "H3", "Бусад"],
    "Hyundai": ["Accent", "Azera", "Elantra", "Elantra N", "Equus", "Excel", "Genesis Coupe", "IONIQ 5", "IONIQ 6", "IONIQ Hybrid", "Kona", "Palisade", "Santa Cruz", "Santa Fe", "Sonata", "Tiburon", "Tucson", "Veloster", "Venue", "Veracruz", "XG", "Бусад"],
    "Ineos": ["Grenadier"],
    "INFINITI": ["EX", "FX", "G", "I", "J", "JX", "M", "Q", "Q40", "Q50", "Q60", "Q70", "QX", "QX30", "QX50", "QX55", "QX60", "QX70", "QX80", "Бусад"],
    "Isuzu": ["Amigo", "Ascender", "Axiom", "Hombre", "I", "Rodeo", "Trooper", "VehiCROSS", "Бусад"],
    "Jaguar": ["E-PACE", "F-PACE", "F-TYPE", "I-PACE", "S-Type", "X-Type", "XE", "XF", "XJ", "XK", "Бусад"],
    "Jeep": ["Cherokee", "Commander", "Compass", "Gladiator", "Grand Cherokee", "Liberty", "Patriot", "Renegade", "Wagoneer", "Wrangler", "Бусад"],
    "Kaiyi": ["E Series", "V Series", "X Series", "Бусад"],
    "Kia": ["Amanti", "Borrego", "Cadenza", "Carnival", "EV6", "Forte", "K5", "K900", "Niro", "Optima", "Rio", "Rondo", "Sedona", "Seltos", "Sorento", "Soul", "Spectra", "Sportage", "Stinger", "Telluride", "Бусад"],
    "Lada": ["Granta", "Vesta", "Xray", "Largus", "Niva", "Бусад"],
    "Lamborghini": ["Aventador", "Gallardo", "Huracan", "Murcielago", "Urus", "Revuelto", "Diablo", "Бусад"],
    "Land Rover": ["Defender", "Discovery", "LR", "Range Rover", "Range Rover Evoque", "Range Rover Sport", "Range Rover Velar", "Бусад"],
    "Lexus": ["CT", "ES", "GS", "GX", "HS", "IS", "HS", "IS F", "LC", "LFA", "LS", "LX", "NX", "RC", "RX", "RZ", "SC", "TX", "UX", "Бусад"],
    "Lincoln": ["LS", "Mark", "MKC", "MKS", "MKT", "MKK", "MKZ", "Nautilus", "Navigator", "Town Car", "Elise", "Evora", "Exige", "Air", "Бусад"],
    "Li Auto": ["L7", "L8", "L9", "Бусад"],
    "Lotus": ["Evora", "Exige", "Emeya", "Eletre", "Emira", "Elise", "Eleven", "Evija", "Бусад"],
    "Lucid": ["Air", "Gravity", "Бусад"],
    "Maserati": ["Ghibli", "GranSport", "GranTurismo", "Grecale", "MC20", "Levante", "Quattroporte", "Бусад"],
    "MAZDA": ["626", "B-Series", "CX-3", "CX-30", "CX-5", "CX-50", "CX-7", "CX-9", "CX-90", "MAZDA2", "MAZDA3", "MAZDA5", "MAZDA6", "MPV", "MX", "MX-5 Miata", "RX-7", "RX-8", "Protege", "Tribute", "Бусад"],
    "McLaren": ["600LT", "650S", "675LT", "720S", "765LT", "Artura", "GT", "MP4-12C", "F1", "P1", "Бусад"],
    "Mercedes-Benz": ["AMG ONE", "A-Class", "B-Class", "C-Class", "CL-Class", "CLA", "CLK-Class", "CLS", "CLS-Class", "E-Class", "G-Class", "GL-Class", "GLA", "GLA-Class", "GLB", "GLC", "GLC Coupe", "GLE", "GLE Coupe", "GLK-Class", "GLS", "M-Class", "GT", "S-Class", "SL", "SLC", "SLK", "SLS", "SLR McLaren", "Sprinter", "EQE", "EQS", "EQB", "EQG", "Mercedes-Maybach", "Бусад"],
    "MINI": ["Clubman", "Convertible", "Cooper", "Countryman", "Coupe", "Hardtop", "Paceman", "Roadster", "Бусад"],
    "Mitsubishi": ["Diamante", "Eclipse", "Endeavor", "Galant", "i-MiEV", "Lancer", "Mirage", "Montero", "Outlander", "Outlander Sport", "Raider", "Бусад"],
    "MG": ["MG3", "MG4", "MG5", "MG ZS", "MG EHS", "MG MARVEL R", "MG CYBERSTER"],
    "Nissan": ["200SX", "240SX", "300ZX", "350Z", "370Z", "Advan", "Altima", "Ariya", "Armada", "Avenir", "Bluebird", "Caravan", "Cedric", "Cima Q45", "cube", "Datsun", "Dualis", "Elgrand", "Frontier", "Fuga", "GT-R",
         "Joy", "JUKE", "Latio", "Laurel", "LEAF", "Liberty", "Kicks", "King Cab", "March", "Maxima", "Mistral", "Murano", "Navara", "Note",
         "NV1500", "NV200", "NV2500", "NV3500", "NX", "Pathfinder", "Patrol", "Presage", "Primera", "Pulsar", "Quest", "Rasheen", "R'nessa", "Rogue", "Sentra", "Serena", "Skyline", "Sunny", "Sylphy", "Silvia", 
         "Teana", "Terrano", "Tiida", "Titan", "Vanette", "Versa", "Wingroad", "X-Trail", "Xterra", "Z", "Бусад"],
    "Porsche": ["718 Boxster", "718 Cayman", "718 Spyder", "911", "918", "968", "Boxster", "Carrera GT", "Cayenne", "Cayenne Coupe", "Cayman", "Macan", "Panamera", "Taycan", "Бусад"],
    "Ram": ["1500", "2500", "3500", "C/V", "ProMaster", "Бусад"],
    "Renault": ["5 E-Tech", "Arkana", "Austral", "Alaskan", "Captur", "Clio", "Duster", "Espace", "Kwid", "Kangoo", "Kardian", "Koleos", "Megane", "Sandero", "Twingo", "Taliant", "Van", "Truck", "Бусад"],
    "Rivian": ["R1S", "R1T", "Бусад"],
    "Rolls-Royce": ["Cullinan", "Dawn", "Ghost", "Phantom", "Spectre", "Wraith", "Бусад"],
    "Saab": ["9-2X", "9-3", "9-5", "9-7X", "900", "9000", "Бусад"],
    "smart": ["fortwo cabrio", "fortwo electric drive", "fortwo EQ cabrio", "fortwo EQ coupe", "Бусад"],
    "Subaru": ["Ascent", "B9 Tribeca", "Baja", "BRZ", "Crosstrek", "Forester", "Impreza", "Justy", "Legacy", "Loyale", "Outback", "SVX", "Tribeca", "WRX", "XV Crosstrek", "Бусад"],
    "Suzuki": ["Aerio", "Equator", "Esteem", "Forenza", "Grand Vitara", "Kizashi", "Reno", "Samurai", "Sidekick", "Swift", "SX4", "Verona", "Vitara", "X-90", "XL-7", "XL7", "Бусад"],
    "Tesla": ["Cybertruck", "Model S", "Model 3", "Model X", "Model Y", "Roadster", "Бусад"],
    "Toyota": ["4Runner", "86", "Allex", "Allion", "Alphard", "Altezza", "Aqua", "Auris", "Avensis", "Avalon", "Axio", "Belta", "Brevis", "bZ3", "bZ4X", "C-HR", "Camry", "Camry Hybrid", "Caldina", "Cami", "Carina", 
        "Celica", "Chaser", "Corolla", "Corona", "Crown", "Duet", "Echo", "Esquire", "Estima", "FJ Cruiser", "Fielder", "Fortuner", "Funcargo", "Gaia", "Granvia", "GR Corolla", "GR86", "GR Supra", "Harrier", "Highlander", 
        "Hiace", "Hilux", "Ipsum", "Isis", "Ist", "iQ", "Kluger", "Land Cruiser", "Land Cruiser Prado", "Mark", "Matrix", "Mega Cruiser", "Mirai", "MR2", "Nadia", "Noah", "Paseo", "Passo", "Pickup", "Platz", "Premio", 
        "Previa", "Prius", "Prius C", "Prius Prime", "Prius V", "Probox", "Progres", "Raum", "Ractis", "RAV4", "RAV4 Prime", "Rumion", "Runx", "Rush", "Sai", "Sequoia", "Sienna", "Sienta", "Spacio", "Spade Porte", 
        "Sprinter", "Succeed", "Solara", "Supra", "T100", "Tacoma", "Tercel", "Townace", "Tundra", "Van", "Vanguard", "Venza", "Vellfire", "Verossa", "Vista", "Vitz", "Voxy", "Yaris", "Wildlander", "Will", "Windom", "Wish", "Бусад"],
    "Volkswagen": ["Arteon", "Atlas", "Beetle", "Cabrio", "CC", "e-Golf", "Eurovan", "GLI", "Golf", "Golf GTI", "GTI", "Jetta", "Jetta GLI", "Passat", "Routan", "Taos", "Tiguan", "Touareg", "Бусад"],
    "Volvo": ["850", "960", "C30", "C40", "C70", "S40", "S60", "S70", "S80", "S90", "V40", "V50", "V60", "V70", "V90", "XC40", "XC60", "XC70", "XC90", "Бусад"],
    "Бусад": ["Бусад"]
        // ... add more makes and their models
  };
  
  export const carMakes = Object.keys(carData);