// src/components/VerifyEmail.js

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Typography, Container, Box, CircularProgress } from '@mui/material';
import { verifyEmail } from '../utils/db';
import { useAuth } from '../contexts/AuthContext';

export default function VerifyEmail() {
  const [status, setStatus] = useState('verifying');
  const { token } = useParams();
  const navigate = useNavigate();
  const { setToken, currentUser } = useAuth();

  useEffect(() => {
    const verify = async () => {
      try {
        const response = await verifyEmail(token);
        console.log('Verification response:', response);
        
        if (response.token) {
          // Use setToken to handle authentication
          setToken(response.token);
          setStatus('success');
        } else {
          throw new Error('No token received from server');
        }
      } catch (error) {
        console.error('Verification error:', error);
        setStatus('error');
      }
    };
    
    if (!currentUser) {
      verify();
    } else {
      setStatus('success');
    }
  }, [token, navigate, setToken, currentUser]);

  useEffect(() => {
    if (status === 'success') {
      const timer = setTimeout(() => navigate('/'), 3000);
      return () => clearTimeout(timer);
    }
  }, [status, navigate]);

  return (
    <Container component="main" maxWidth="xs">
      <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {status === 'verifying' && (
          <>
            <CircularProgress />
            <Typography component="h1" variant="h5" sx={{ mt: 2 }}>
              Имэйл хаягаа баталгаажуулж байна...
            </Typography>
          </>
        )}
        {status === 'success' && (
          <Typography component="h1" variant="h5">
            Имэйл хаяг амжилттай баталгаажлаа! Нүүр хуудас руу шилжиж байна...
          </Typography>
        )}
        {status === 'error' && (
          <Typography component="h1" variant="h5" color="error">
            Имэйл хаяг баталгаажуулж чадсангүй. Холбоос хүчингүй эсвэл хугацаа нь дууссан байж болзошгүй.
          </Typography>
        )}
      </Box>
    </Container>
  );
}
