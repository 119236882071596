// src/components/RequestCar.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  TextField, Button, Typography, Box, Select, MenuItem, FormControl, 
  InputLabel, Paper, Slider, CircularProgress, Alert, Card, CardContent, 
  CardActionArea, Fade, Grow, Grid, Chip, Checkbox, FormControlLabel, InputAdornment
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { carData, carMakes } from '../data/carData';
import { useAuth } from '../contexts/AuthContext';
import { saveBuyerRequest } from '../utils/db';
import { motion } from 'framer-motion';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import SearchIcon from '@mui/icons-material/Search';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DescriptionIcon from '@mui/icons-material/Description';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';

const primaryBlue = '#0052FF';
const secondaryColor = '#FFA500';

const theme = createTheme({
  typography: {
    fontFamily: 'Rubik, Arial, sans-serif',
  },
  palette: {
    primary: {
      main: primaryBlue,
    },
    secondary: {
      main: secondaryColor,
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '16px',
          boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
          transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
          '&:hover': {
            transform: 'translateY(-5px)',
            boxShadow: '0 6px 25px rgba(0,0,0,0.15)',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '25px',
          textTransform: 'none',
          fontWeight: 600,
        },
      },
    },
  },
});

const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  borderRadius: '16px',
  boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
}));

const ChoiceCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(3),
  borderRadius: '16px',
  boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
  cursor: 'pointer',
}));

const AnimatedIcon = styled(motion.div)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '48px',
  color: primaryBlue,
  marginBottom: '20px',
});

const RequestCar = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [step, setStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [knowsExactCar, setKnowsExactCar] = useState(null);
  const [exampleCars, setExampleCars] = useState([]);
  const [currentExampleCar, setCurrentExampleCar] = useState({ make: '', model: '' });

  const [carRequest, setCarRequest] = useState({
    requestType: '',
    make: '',
    model: '',
    yearMadeMin: '',
    yearMadeMax: '',
    yearImportedMin: '',
    yearImportedMax: '',
    priceMin: '',
    priceMax: '',
    odometerMax: '',
    fuelType: '',
    bodyType: '',
    transmission: '',
    drivetrain: '',
    buyerLocation: '', // Initialized as empty
    buyerPhone: '',    // Initialized as empty
    additionalRequirements: '',
    exampleCars: [],
    openToOverseas: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCarRequest(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handlePriceChange = (field) => (e) => {
    const value = e.target.value.replace(/[^\d]/g, '');
    if (value === '' || (parseInt(value, 10) <= 9999999999)) {
      setCarRequest(prev => ({
        ...prev,
        [field]: value === '' ? '' : parseInt(value, 10).toLocaleString()
      }));
    }
  };

  const handleExampleCarChange = (e) => {
    const { name, value } = e.target;
    setCurrentExampleCar(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const addExampleCar = () => {
    if (currentExampleCar.make && currentExampleCar.model && exampleCars.length < 3) {
      const newExampleCar = `${currentExampleCar.make} ${currentExampleCar.model}`;
      setExampleCars(prev => [...prev, newExampleCar]);
      setCarRequest(prev => ({
        ...prev,
        exampleCars: [...prev.exampleCars, newExampleCar]
      }));
      setCurrentExampleCar({ make: '', model: '' });
    }
  };

  const removeExampleCar = (index) => {
    setExampleCars(prev => prev.filter((_, i) => i !== index));
    setCarRequest(prev => ({
      ...prev,
      exampleCars: prev.exampleCars.filter((_, i) => i !== index)
    }));
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    setError(null);
  
    // Create a copy of carRequest
    let requestToSubmit = { ...carRequest };
  
    // If the request type is general, remove make and model
    if (requestToSubmit.requestType === 'general') {
      delete requestToSubmit.make;
      delete requestToSubmit.model;
    }
  
    // Remove formatting from price fields
    if (requestToSubmit.priceMin) {
      requestToSubmit.priceMin = parseInt(requestToSubmit.priceMin.replace(/,/g, ''), 10);
    }
    if (requestToSubmit.priceMax) {
      requestToSubmit.priceMax = parseInt(requestToSubmit.priceMax.replace(/,/g, ''), 10);
    }
  
    // Remove any empty fields
    Object.keys(requestToSubmit).forEach(
      (key) =>
        (requestToSubmit[key] === '' || requestToSubmit[key] === null) &&
        delete requestToSubmit[key]
    );
  
    console.log('Submitting car request:', requestToSubmit);
  
    try {
      const response = await saveBuyerRequest(requestToSubmit);
      console.log('Buyer request saved successfully:', response);
      setIsLoading(false);
      navigate('/buyer-requests');
    } catch (error) {
      console.error('Error saving buyer request:', error);
      setError('Failed to save buyer request. Please try again.');
      setIsLoading(false);
    }
  };
  

  const handleChoiceSelection = (isExact) => {
    setKnowsExactCar(isExact);
    setCarRequest(prev => ({ ...prev, requestType: isExact ? 'specific' : 'general' }));
    setStep(1);
  };

  const isStepValid = () => {
    switch (step) {
      case 0:
        return true; // Initial step has its own validation
      case 1:
        return knowsExactCar 
          ? (carRequest.make && carRequest.model)
          : carRequest.bodyType;
      case 2:
        return carRequest.priceMin && carRequest.priceMax;
      case 3:
        return carRequest.buyerPhone && carRequest.buyerLocation;
      case 4:
        return true; // Overseas Options is optional
      default:
        return false;
    }
  };

  const renderStep = () => {
    switch (step) {
      case 0:
        return (
          <Grow in={true}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h5" gutterBottom align="center">
                  Та яг ямар машин авмаар байгаа гэдгээ мэдэж байгаа юу?
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ChoiceCard onClick={() => handleChoiceSelection(true)}>
                  <CheckCircleOutlineIcon style={{ fontSize: 60, color: primaryBlue, marginBottom: 16 }} />
                  <Typography variant="h6" align="center">
                    Тийм
                  </Typography>
                </ChoiceCard>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ChoiceCard onClick={() => handleChoiceSelection(false)}>
                  <HelpOutlineIcon style={{ fontSize: 60, color: primaryBlue, marginBottom: 16 }} />
                  <Typography variant="h6" align="center">
                    Үгүй, яг тодорхой мэдэхгүй
                  </Typography>
                </ChoiceCard>
              </Grid>
            </Grid>
          </Grow>
        );
      case 1:
        return (
          <Fade in={true}>
            <StyledCard>
              <CardContent>
                <AnimatedIcon
                  initial={{ rotate: -90 }}
                  animate={{ rotate: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <DirectionsCarIcon fontSize="inherit" />
                </AnimatedIcon>
                <Typography variant="h5" gutterBottom align="center">
                  {knowsExactCar ? "Хайж байгаа машин нь юу вэ?" : "Та ямар төрлийн машин хайж байна?"}
                </Typography>
                {knowsExactCar ? (
                  <>
                    <FormControl fullWidth margin="normal">
                      <InputLabel id="make-label">Үйлдвэрлэгч</InputLabel>
                      <Select
                        labelId="make-label"
                        name="make"
                        value={carRequest.make}
                        label="Make"
                        onChange={handleChange}
                        required
                      >
                        {carMakes.map((make, index) => (
                          <MenuItem key={index} value={make}>{make}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                      <InputLabel id="model-label">Загвар</InputLabel>
                      <Select
                        labelId="model-label"
                        name="model"
                        value={carRequest.model}
                        label="Model"
                        onChange={handleChange}
                        disabled={!carRequest.make}
                        required
                      >
                        {carRequest.make && carData[carRequest.make].map((model, index) => (
                          <MenuItem key={index} value={model}>{model}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </>
                ) : (
                  <>
                    <FormControl fullWidth margin="normal">
                      <InputLabel id="body-type-label">Машины Төрөл</InputLabel>
                      <Select
                        labelId="body-type-label"
                        name="bodyType"
                        value={carRequest.bodyType}
                        label="Car Type"
                        onChange={handleChange}
                        required
                      >
                        {['Суудлын Машин (Sedan)', 'Жийп (SUV)', 'Дунд Оврын Жийп (Crossover)', 'Вагон (Wagon)', 'Гэр бүлийн машин (Van)', 'Пик-ап трак', '2 хаалгат купе', 'Конвертибль'].map((option) => (
                          <MenuItem key={option} value={option}>{option}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Typography variant="subtitle1" gutterBottom>
                      3 хүртэлх тооны жишээ машин оруулах боломжтой (Заавал биш):
                    </Typography>
                    <Box display="flex" alignItems="center" mb={2}>
                      <FormControl sx={{ mr: 1, flexGrow: 1 }}>
                        <InputLabel id="example-make-label">Үйлдвэрлэгч</InputLabel>
                        <Select
                          labelId="example-make-label"
                          name="make"
                          value={currentExampleCar.make}
                          label="Make"
                          onChange={handleExampleCarChange}
                        >
                          {carMakes.map((make, index) => (
                            <MenuItem key={index} value={make}>{make}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl sx={{ mr: 1, flexGrow: 1 }}>
                        <InputLabel id="example-model-label">Загвар</InputLabel>
                        <Select
                          labelId="example-model-label"
                          name="model"
                          value={currentExampleCar.model}
                          label="Model"
                          onChange={handleExampleCarChange}
                          disabled={!currentExampleCar.make}
                        >
                          {currentExampleCar.make && carData[currentExampleCar.make].map((model, index) => (
                            <MenuItem key={index} value={model}>{model}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <Button onClick={addExampleCar} disabled={exampleCars.length >= 3} type="button">
                        Нэмэх
                      </Button>
                    </Box>
                    <Box>
                      {exampleCars.map((car, index) => (
                        <Chip
                          key={index}
                          label={car}
                          onDelete={() => removeExampleCar(index)}
                          sx={{ mr: 1, mb: 1 }}
                        />
                      ))}
                    </Box>
                  </>
                )}
              </CardContent>
            </StyledCard>
          </Fade>
        );
      case 2:
        return (
          <Fade in={true}>
            <StyledCard>
              <CardContent>
                <AnimatedIcon
                  initial={{ y: -20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <AttachMoneyIcon fontSize="inherit" />
                </AnimatedIcon>
                <Typography variant="h5" gutterBottom align="center">
                  Таны Төсөв Хэд Вэ?
                </Typography>
                <TextField
                  fullWidth
                  margin="normal"
                  name="priceMin"
                  label="Хамгийн бага үнэ"
                  value={carRequest.priceMin}
                  onChange={handlePriceChange('priceMin')}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">₮</InputAdornment>,
                  }}
                />
                
                <TextField
                  fullWidth
                  margin="normal"
                  name="priceMax"
                  label="Хамгийн их үнэ"
                  value={carRequest.priceMax}
                  onChange={handlePriceChange('priceMax')}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">₮</InputAdornment>,
                  }}
                />
              </CardContent>
            </StyledCard>
          </Fade>
        );
      case 3:
        return (
          <Fade in={true}>
            <StyledCard>
              <CardContent>
                <AnimatedIcon
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <DescriptionIcon fontSize="inherit" />
                </AnimatedIcon>
                <Typography variant="h5" gutterBottom align="center">
                  Нэмэлт Мэдээлэл (Заавал биш)
                </Typography>
                <Typography variant="subtitle1" gutterBottom sx={{ mt: 2, fontWeight: 'bold' }}>
                  Үйлдвэрлэсэн Он Хязгаар
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      margin="normal"
                      name="yearMadeMin"
                      label="2015"
                      type="number"
                      value={carRequest.yearMadeMin}
                      onChange={handleChange}
                      inputProps={{ min: 0 }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      margin="normal"
                      name="yearMadeMax"
                      label="2024"
                      type="number"
                      value={carRequest.yearMadeMax}
                      onChange={handleChange}
                      inputProps={{ min: 0 }}
                    />
                  </Grid>
                  </Grid>

                  <Typography variant="subtitle1" gutterBottom sx={{ mt: 2, fontWeight: 'bold' }}>
                    Орж Ирсэн Он Хязгаар
                  </Typography>
                  <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      margin="normal"
                      name="yearImportedMin"
                      label="2022"
                      type="number"
                      value={carRequest.yearImportedMin}
                      onChange={handleChange}
                      inputProps={{ min: 0 }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      margin="normal"
                      name="yearImportedMax"
                      label="2024"
                      type="number"
                      value={carRequest.yearImportedMax}
                      onChange={handleChange}
                      inputProps={{ min: 0 }}
                    />
                  </Grid>
                </Grid>
                <TextField
                  fullWidth
                  margin="normal"
                  name="odometerMax"
                  label="Гүйлтнээс Доош"
                  type="number"
                  value={carRequest.odometerMax}
                  onChange={handleChange}
                  inputProps={{ min: 0 }}
                />
                <FormControl fullWidth margin="normal">
                  <InputLabel id="fuel-type-label">Шатахууны Төрөл</InputLabel>
                  <Select
                    labelId="fuel-type-label"
                    name="fuelType"
                    value={carRequest.fuelType}
                    label="Fuel Type"
                    onChange={handleChange}
                  >
                    {['Бензин', 'Диеэль', 'Хайбрид', 'Цахилгаан'].map((option) => (
                      <MenuItem key={option} value={option}>{option}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  fullWidth
                  margin="normal"
                  name="additionalRequirements"
                  label="Нэмэлт Шаардлагууд"
                  multiline
                  rows={4}
                  value={carRequest.additionalRequirements}
                  onChange={handleChange}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  name="buyerPhone"
                  label="Холбогдох Утасны Дугаар"
                  value={carRequest.buyerPhone}
                  onChange={handleChange}
                  required
                  inputProps={{ maxLength: 20 }}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  name="buyerLocation"
                  label="Байршил"
                  value={carRequest.buyerLocation}
                  onChange={handleChange}
                  required
                  inputProps={{ maxLength: 100 }}
                />
              </CardContent>
            </StyledCard>
          </Fade>
        );
      case 4:
        return (
          <Fade in={true}>
            <StyledCard>
              <CardContent>
                <AnimatedIcon
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <FlightTakeoffIcon fontSize="inherit" />
                </AnimatedIcon>
                <Typography variant="h5" gutterBottom align="center">
                  Гадаадаас Ачуулах
                </Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={carRequest.openToOverseas}
                      onChange={(e) => setCarRequest(prev => ({ ...prev, openToOverseas: e.target.checked }))}
                      name="openToOverseas"
                    />
                  }
                  label="Хэрвээ таны хайсан машин олдохгүй тохиолдолд гадаадаас танд зориулж ачуулах машинуудыг сонирхох уу?"
                />
              </CardContent>
            </StyledCard>
          </Fade>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    console.log(`Current step: ${step}`);
  }, [step]);

  return (
    <ThemeProvider theme={theme}>
      <Box className="request-car" sx={{ maxWidth: 600, margin: '0 auto', padding: 3 }}>
        <form onSubmit={handleSubmit}>
          {renderStep()}
          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}
          {step > 0 && (
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
              <Button
                variant="outlined"
                onClick={() => setStep((prev) => Math.max(0, prev - 1))}
                disabled={isLoading}
              >
                Буцах
              </Button>
              <Button
                component="button"
                variant="contained"
                color="primary"
                type="button" // Always set the type to 'button'
                onClick={
                    isLoading
                    ? undefined
                    : () => {
                        if (step < 4) {
                            console.log(`Advancing from step ${step} to step ${step + 1}`);
                            setStep((prev) => prev + 1);
                        } else {
                            handleSubmit(); // Call handleSubmit directly
                        }
                        }
                }
                disabled={isLoading || !isStepValid()}
                >
                {isLoading ? (
                    <CircularProgress size={24} color="inherit" />
                ) : step === 4 ? (
                    'Зар Нийтлэх'
                ) : (
                    'Дараах'
                )}
                </Button>
            </Box>
          )}
        </form>
      </Box>
    </ThemeProvider>
  );
};

export default RequestCar;