// src/components/Login.js

import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { TextField, Button, Typography, Container, Box, Alert } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';

export default function Login() {
  const [email, setEmail] = useState(''); // Using 'email'
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [lockoutTime, setLockoutTime] = useState(0); // In seconds
  const { login } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const clientId = '840808628754-7on38fm3kshkd0ths815r2vshvav0bhg.apps.googleusercontent.com';
  const redirectUri = 'https://softmax.mn/api/auth/google/callback';

  const handleGoogleLogin = () => {
    const googleAuthUrl =
      `https://accounts.google.com/o/oauth2/v2/auth?` +
      `client_id=${clientId}` +
      `&redirect_uri=${encodeURIComponent(redirectUri)}` +
      `&response_type=code` +
      `&scope=${encodeURIComponent('email profile')}` +
      `&access_type=offline` +
      `&prompt=consent`;

    window.location.href = googleAuthUrl;
  };


  useEffect(() => {
    if (location.state?.message) {
      setMessage(location.state.message);
    }
  }, [location]);

  useEffect(() => {
    // Check if there's a lockout end time in local storage
    const lockoutEndTime = localStorage.getItem('lockoutEndTime');
    if (lockoutEndTime) {
      const remainingTime = Math.floor((new Date(lockoutEndTime) - new Date()) / 1000);
      if (remainingTime > 0) {
        setLockoutTime(remainingTime);
      } else {
        localStorage.removeItem('lockoutEndTime');
      }
    }
  }, []);

  useEffect(() => {
    let timer;
    if (lockoutTime > 0) {
      timer = setInterval(() => {
        setLockoutTime((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(timer);
            localStorage.removeItem('lockoutEndTime');
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [lockoutTime]);

  async function handleSubmit(e) {
    e.preventDefault();
    setError('');
    setMessage('');
    try {
      await login(email, password); // Pass 'email' and 'password'
      navigate('/');
    } catch (error) {
      if (error.message.includes('locked')) {
        // Extract remaining time from the error message
        const match = error.message.match(/(\d+) minutes and (\d+) seconds/);
        let totalSeconds = 600; // Default to 10 minutes
        if (match) {
          const minutes = parseInt(match[1], 10);
          const seconds = parseInt(match[2], 10);
          totalSeconds = minutes * 60 + seconds;
        }
        setLockoutTime(totalSeconds);
        // Store the lockout end time in local storage
        const lockoutEndTime = new Date(new Date().getTime() + totalSeconds * 1000);
        localStorage.setItem('lockoutEndTime', lockoutEndTime.toISOString());

        setError('Таны нэвтрэх эрх хэт их удаан оролдлогоо хийсэн тул энэ хэсэгт биелэгдсэн. Дахин оролдоно уу.');
      } else {
        setError(error.message || 'Нэвтрэхэд амжилтгүй боллоо');
      }
    }
  }

  // Function to format time in MM:SS
  const formatTime = (seconds) => {
    const m = Math.floor(seconds / 60)
      .toString()
      .padStart(2, '0');
    const s = (seconds % 60).toString().padStart(2, '0');
    return `${m}:${s}`;
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <Typography component="h1" variant="h5">
          Нэвтрэх
        </Typography>
        {message && <Alert severity="info" sx={{ width: '100%', mt: 2 }}>{message}</Alert>}
        {error && <Alert severity="error" sx={{ width: '100%', mt: 2 }}>{error}</Alert>}
        {lockoutTime > 0 && (
          <Alert severity="warning" sx={{ width: '100%', mt: 2 }}>
            Таны нэвтрэх эрх {formatTime(lockoutTime)} цагийн турш хязгаарлагдсан байна.
          </Alert>
        )}
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Имэйл Хаяг"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={lockoutTime > 0}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Нууц Үг"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            disabled={lockoutTime > 0}
          />
          <Link to="/reset-password" style={{ alignSelf: 'flex-end', marginTop: '8px' }}>
            Нууц үгээ мартсан уу?
          </Link>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={lockoutTime > 0}
          >
            Нэвтрэх
          </Button>
        </Box>
        {/* Add Google login button */}
        <Button
          fullWidth
          variant="contained"
          startIcon={<GoogleIcon />}
          onClick={handleGoogleLogin}
          sx={{
            mt: 2,
            backgroundColor: '#fff',
            color: '#757575',
            border: '1px solid #dadce0',
            '&:hover': {
              backgroundColor: '#f1f3f4',
              border: '1px solid #dadce0',
            },
          }}
        >
          Gmail-ээр нэвтрэх
        </Button>
      </Box>
    </Container>
  );
}
