// src/contexts/AuthContext.js

import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

const API_URL = process.env.REACT_APP_API_URL || 'https://softmax.mn/api';

const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// export function getCSRFToken() {
//   const name = 'csrftoken';
//   const value = `; ${document.cookie}`;
//   const parts = value.split(`; ${name}=`);
//   if (parts.length === 2) return parts.pop().split(';').shift();
// }

// api.interceptors.request.use(function (config) {
//   config.headers['X-CSRFToken'] = getCSRFToken();
//   return config;
// });

 // Add a request interceptor
 api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Token ${token}`;
    } else {
      delete config.headers['Authorization'];
    }
    return config;
  },
  (error) => Promise.reject(error)
);



export { api };

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const googleSignUp = async (accessToken) => {
    try {
      const response = await api.post('/auth/google/', {
        access_token: accessToken
      });
      
      if (response.data.token) {
        setToken(response.data.token);
        return response.data;
      }
    } catch (error) {
      throw new Error(error.response?.data?.message || 'Failed to sign up with Google');
    }
  };

  // Fetch CSRF Token on initial load
  useEffect(() => {
    const fetchCSRFToken = async () => {
      try {
        await api.get('/csrf-token/');
      } catch (error) {
        console.error("Error fetching CSRF token:", error);
      }
    };

    fetchCSRFToken();
  }, []);


  useEffect(() => {
    const fetchUser = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        api.defaults.headers.common['Authorization'] = `Token ${token}`;
        try {
          const response = await api.get('/user/');
          setCurrentUser(response.data);
        } catch (error) {
          console.error("Error fetching user data:", error);
          localStorage.removeItem('token');
          delete api.defaults.headers.common['Authorization'];  // Add this line
        }
      }
      setLoading(false);
    };
  
    fetchUser();
  }, []);

  // Function to set token and fetch user data
  const setToken = (token) => {
    localStorage.setItem('token', token);
    // Fetch user info
    api.get('/user/')
      .then(response => {
        setCurrentUser(response.data);
      })
      .catch(error => {
        console.error('Error fetching user after setting token:', error);
        logout(); // Clear token if fetching user fails
      });
  };

  async function signup(username, email, phoneNumber, password) {
    try {
      const response = await api.post('/signup/', { username, email, phone_number: phoneNumber, password });
      // After signup, the user is not active until email is verified
      return response.data;
    } catch (error) {
      console.error("Signup error:", error.response ? error.response.data : error.message);
      throw new Error(error.response?.data?.message || 'An error occurred during signup');
    }
  }

  async function login(email, password) {  // Updated to use 'email' only
    try {
      const response = await api.post('/login/', { email, password }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });  // Send 'email' and 'password' as per backend
      setToken(response.data.token); // Use setToken to handle token and user data
      return response.data;
    } catch (error) {
      console.error("Login error:", error.response ? error.response.data : error.message);
      throw new Error(error.response?.data?.error || 'An error occurred during login');
    }
  }

  function logout() {
    localStorage.removeItem('token');
    delete api.defaults.headers.common['Authorization'];
    setCurrentUser(null);
  }

  async function resetPassword(email) {
    try {
      const response = await api.post('/reset-password/', { email });
      return response.data;
    } catch (error) {
      console.error("Password reset error:", error.response ? error.response.data : error.message);
      throw new Error(error.response?.data?.error || 'An error occurred during password reset');
    }
  }

  async function confirmResetPassword(token, newPassword) {
    try {
      const response = await api.post('/confirm-reset-password/', { token, new_password: newPassword });
      return response.data;
    } catch (error) {
      console.error("Password reset confirmation error:", error.response ? error.response.data : error.message);
      throw new Error(error.response?.data?.error || 'An error occurred during password reset confirmation');
    }
  }

  const value = {
    currentUser,
    signup,
    login,
    logout,
    resetPassword,
    confirmResetPassword,
    googleSignUp,
    setToken, // Expose setToken to the context
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
