// src/components/Home.js
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../styles/Home.css';
import toyotaLogo from '../assets/brand-logos/toyota.jpg';
import nissanLogo from '../assets/brand-logos/nissan.jpg';
import hondaLogo from '../assets/brand-logos/honda.jpg';
import bmwLogo from '../assets/brand-logos/bmw.jpg';
import mercedesLogo from '../assets/brand-logos/mercedes.jpg';
import lexusLogo from '../assets/brand-logos/lexus.png';
import { carData, carMakes } from '../data/carData';
import CarListings from './CarListings';
import Lines from './Lines';
import Footer from './Footer';

const Home = () => {
  const [selectedMake, setSelectedMake] = useState('');
  const [selectedModel, setSelectedModel] = useState('');
  const [yearMin, setYearMin] = useState('');
  const [yearMax, setYearMax] = useState('');
  const [location, setLocation] = useState('');

  const navigate  = useNavigate();

  const handleBrandClick = (brandName) => {
    navigate(`/listings?make=${brandName}`);
  };

  const handleMakeChange = (e) => {
    setSelectedMake(e.target.value);
    setSelectedModel('');
  }
  const handleModelChange = (e) => {
    setSelectedModel(e.target.value);
  }

  const handleYearMinChange = (e) => {
    setYearMin(e.target.value);
  }

  const handleYearMaxChange = (e) => {
    setYearMax(e.target.value);
  }

  const handleLocationChange = (e) => {
    setLocation(e.target.value);
  }

  const handleSearch = (e) => {
    e.preventDefault();
    const searchParams = new URLSearchParams();
    if (selectedMake) searchParams.append('make', selectedMake);
    if (selectedModel) searchParams.append('model', selectedModel);
    if (yearMin) searchParams.append('yearMin', yearMin);
    if (yearMax) searchParams.append('yearMax', yearMax);
    if (location) searchParams.append('location', location);

    navigate(`/listings?${searchParams.toString()}`);
  }

  const carBrands = [
    { name: 'Toyota', logo: toyotaLogo, models: '100+' },
    { name: 'Nissan', logo: nissanLogo, models: '50+' },
    { name: 'Honda', logo: hondaLogo, models: '100+' },
    { name: 'BMW', logo: bmwLogo, models: '50+' },
    { name: 'Mercedes-Benz', logo: mercedesLogo, models: '75+' },
    { name: 'Lexus', logo: lexusLogo, models: '60+' },
  ];

  return (
    <div className="home">
      <div className="top-section">
        <div className="car-search">
          <h2>Өөрт Тохирох Машинаа Олоорой</h2>
          <form onSubmit={handleSearch}>
            <div className='select-wrapper'>
              <select
                value={selectedMake}
                onChange={handleMakeChange}
                className="custom-select"
              >
                <option value="">Үйлдвэрлэгчээ Сонгох</option>
                {carMakes.map((make, index) => (
                  <option key={index} value={make}>{make}</option>
                ))}
              </select>
            </div>
            <div className='select-wrapper'>
              <select
                value={selectedModel}
                onChange={handleModelChange}
                className="custom-select"
                disabled={!selectedMake}
              >
                <option value="">Загвараа Сонгох</option>
                {selectedMake && carData[selectedMake].map((model, index) => (
                  <option key={index} value={model}>{model}</option>
                ))}
              </select>
            </div>
            <div className="year-range">
              <input 
                type="number" 
                placeholder="Доод Он" 
                value={yearMin}
                onChange={handleYearMinChange}
                min="1900"
                max={new Date().getFullYear()}
              />
              <input 
                type="number" 
                placeholder="Дээд Он" 
                value={yearMax}
                onChange={handleYearMaxChange}
                min="1900"
                max={new Date().getFullYear()}
              />
            </div>
            <input 
              type="text" 
              placeholder="Байршил" 
              value={location}
              onChange={handleLocationChange}
            />
            <button type="submit">Хайх</button>
          </form>
        </div>
        <div className="signup-section">
          <h1>Автомашины төвлөрсөн маркет</h1>
          <p>Үнэ төлбөргүйгээр зараа байршуулж хамгийн хурднаар зөв хүндээ хүрэх боломж.</p>
          <div className="sell-car-button">
            <Link to="/sell-my-car">
              <button className="sell-car-btn">Зар байршуулах</button>
            </Link>
          </div>
        </div>
      </div>
      
      <div className="car-brands-section">
        <div className="car-brands-content">
          <div className="car-brands-text">
            <h2>Топ Эрэлттэй Брэндүүд</h2>
            <p>Зуун зуун өөр төрлийн машинуудыг нэг доороос харьцуулан сонгох боломж.</p>
            <Link to="/listings" className="see-more-button">Бүх Брэндүүд</Link>
          </div>
          <div className="car-brands-grid">
            {carBrands.map((brand, index) => (
              <div 
                key={index} 
                className="car-brand-card"
                onClick={() => handleBrandClick(brand.name)}
                style={{ cursor: 'pointer' }}
              >
                <div className="logo-container">
                  <img src={brand.logo} alt={`${brand.name} logo`} className="brand-logo" />
                </div>
                <div className="brand-info">
                  <h3>{brand.name}</h3>
                  <p>{brand.models} models</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="get-started-section">
        <div className="get-started-content">
          <div className="get-started-text">
            <h2>Амархан шийдэл</h2>
            <p>Хаягаа үүсгээд зараа тавиад хормын дотор зөв хүндээ хүрээрэй.</p>
            <Link to="/signup" className="create-account-btn">Бүртгүүлье</Link>
          </div>
          <div className="stats">
            <div className="stat-item">
              <h3>$50B+</h3>
              <p>ANNUAL SALES VOLUME</p>
            </div>
            <div className="stat-item">
              <h3>500+</h3>
              <p>VARIETY OF CARS</p>
            </div>
            <div className="stat-item">
              <h3>50+</h3>
              <p>COUNTRIES SERVED</p>
            </div>
          </div>
        </div>
      </div>
      <div className="trusted-marketplace-section">
        <div className="trusted-marketplace-content">
          <div className="trusted-marketplace-header">
            <h2>The most trusted car marketplace</h2>
            <p>We're the most trusted peer top peer platform for people and businesses to buy, sell, and manage vehicles.</p>
          </div>
          <div className="feature-cards">
            <div className="feature-card">
              <div className="feature-icon largest-marketplace"></div>
              <h3>Democratizing the public auto marketplace</h3>
              <p>Our no special treatment policy protects our users from falling into manifactured market bubble.</p>
            </div>
            <div className="feature-card">
              <div className="feature-icon protected-transactions"></div>
              <h3>Nice guys do not always finish last</h3>
              <p>Just listed your car but you can't find it behind 100+ sponsored Ads? Don't worry, we are all equal here.</p>
            </div>
            <div className="feature-card">
              <div className="feature-icon customer-support"></div>
              <h3>Worldwide Selection</h3>
              <p>Ship your car from anywhere in the world. Buy your next car from anywhere in the world. </p>
            </div>
            <div className="feature-card">
              <div className="feature-icon best-practices"></div>
              <h3>Industry best practices</h3>
              <p>Our platform supports a variety of the most popular car brands and models. Lost in the sauce? We have the most advanced filtering system at your fingertip.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="lines-section">
        <Lines />
      </div>

      <Footer />
    </div>
  );
};

export default Home;
