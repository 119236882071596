// src/components/ProfilePage.js

import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate, Link } from 'react-router-dom';
import { 
  Typography, Box, Container, Button, CircularProgress, Grid, Card, CardContent,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  Alert
} from '@mui/material';
import { getUserInfo, getCarListings } from '../utils/db';
import { getTimeSincePosted } from '../utils/timeUtils';
import PurchaseSlots from './PurchaseSlots';
import { api } from '../contexts/AuthContext';
import { isMobileDevice } from '../utils/deviceDetection';

const ProfilePage = () => {
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(null);
  const [userListings, setUserListings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showPurchaseSlots, setShowPurchaseSlots] = useState(false);
  const [showQRCodeDialog, setShowQRCodeDialog] = useState(false);
  const [qrCodeData, setQrCodeData] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState('qpay'); // Payment method state
  const [khanBankUrl, setKhanBankUrl] = useState('');
  const [shortUrl, setShortUrl] = useState('');
  const [paymentId, setPaymentId] = useState('');
  const [invoiceId, setInvoiceId] = useState('');
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [isPolling, setIsPolling] = useState(false);
  const [error, setError] = useState(null);

  const handleOpenPurchaseSlots = () => {
    setShowPurchaseSlots(true);
  };
  
  const handleClosePurchaseSlots = () => {
    setShowPurchaseSlots(false);
  };
  
  const handleSuccessfulPurchase = async () => {
    try {
      const updatedInfo = await getUserInfo();
      setUserInfo(updatedInfo);
      setShowQRCodeDialog(false);
      setPaymentStatus(null);
    } catch (error) {
      console.error('Error updating user info:', error);
      setError('Failed to update slot information. Please try again.');
    }
  };

  const handleInitiatePayment = (qrCodeUrl, shortUrl, paymentId, invoiceId, method) => {
    setPaymentMethod(method);
    if (method === 'qpay') {
      setQrCodeData(qrCodeUrl);
      setShortUrl(shortUrl);
    } else if (method === 'khanbank') {
      setKhanBankUrl(qrCodeUrl); // qrCodeUrl holds the Khan Bank URL in this context
    }
    setPaymentId(paymentId);
    setInvoiceId(invoiceId);
    setShowQRCodeDialog(true);
    setShowPurchaseSlots(false);
  };

  const handleCheckPaymentStatus = async () => {
    if (!paymentId) {
      setError('No payment ID available. Please try again.');
      return;
    }

    setIsPolling(true);
    setError(null);

    try {
      let response;
      let data;

      if (paymentMethod === 'qpay') {
        response = await api.get(`/check-payment-status/?payment_id=${paymentId}`);
        data = response.data;

        if (data.status === 'PAID' || data.slot_purchase_status === 'completed') {
          setPaymentStatus('completed');
          await handleSuccessfulPurchase();
        } else if (data.status === 'FAILED' || data.slot_purchase_status === 'failed') {
          setPaymentStatus('failed');
          setError('Payment failed. Please try again or choose a different payment method.');
        } else {
          setPaymentStatus('pending');
        }
      } else if (paymentMethod === 'khanbank') {
        response = await api.get(`/khanbank/check-payment-status/?payment_id=${paymentId}`);
        data = response.data;

        if (data.status === 2 || data.slot_purchase_status === 'completed') {
          setPaymentStatus('completed');
          await handleSuccessfulPurchase();
        } else if ([1, 3, 4, 5, 6].includes(data.status) || data.slot_purchase_status === 'failed') {
          setPaymentStatus('failed');
          setError(`Payment failed: ${data.error_message || 'Unknown error'}. Please try again or choose a different payment method.`);
        } else {
          setPaymentStatus('pending');
        }
      } else {
        setError('Unsupported payment method.');
        setIsPolling(false);
        return;
      }

      setIsPolling(false);
    } catch (error) {
      console.error('Error checking payment status:', error);
      setError('Failed to check payment status. Please try again.');
      setIsPolling(false);
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const info = await getUserInfo();
        setUserInfo(info);

        const listings = await getCarListings();
        const userListings = listings.results.filter(car => car.owner === currentUser.id);
        setUserListings(userListings);
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setLoading(false);
      }
    };

    if (currentUser) {
      fetchUserData();
    } else {
      setLoading(false);
    }
  }, [currentUser]);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch {
      console.error("Failed to log out");
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (!currentUser) {
    return (
      <Container maxWidth="lg">
        <Typography variant="h4" component="h1" gutterBottom>
          Та нэвтрээгүй байна
        </Typography>
        <Link to="/login">Нэвтрэх</Link>
      </Container>
    );
  }

  return (
    <div className="profile-page">
      <Container maxWidth="lg" className="content-container">
        <Box className="account-info-section">
          <Typography variant="h4" component="h1" gutterBottom className="section-title">
            Хэрэглэгчийн мэдээлэл
          </Typography>
          <div className="info-container">
            <div className="info-item">
              <span className="info-label">Нэр:</span>
              <span className="info-value">{currentUser.username || 'Тохируулаагүй'}</span>
            </div>
            <div className="info-item">
              <span className="info-label">Имэйл:</span>
              <span className="info-value">{currentUser.email}</span>
              {currentUser.is_phone_verified && <span className="Баталгаажсан">Verified</span>}
            </div>
            <div className="info-item">
              <span className="info-label">Утасны Дугаар:</span>
              <span className="info-value">{currentUser.phone_number || 'Тохируулаагүй'}</span>
            </div>
            <div className="info-item">
              <span className="info-label">Байршил:</span>
              <span className="info-value">{currentUser.location || 'Тохируулаагүй'}</span>
            </div>
            <div className="info-item">
              <span className="info-label">Боломжит зар нийтлэх эрх:</span>
              <span className="info-value">{userInfo ? userInfo.available_listing_slots : 'Уншиж байна...'}</span>
            </div>
          </div>
          <div className="button-container">
            <button className="profile-button logout-button" onClick={handleLogout}>ГАРАХ</button>
          </div>
        </Box>
      </Container>

      <div className="your-listings-section">
        <Container maxWidth="lg" className="content-container">
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography variant="h4" component="h2" className="section-title">
              Таны Зарууд
            </Typography>
            <Button 
              onClick={handleOpenPurchaseSlots} 
              variant="contained" 
              color="primary"
            >
              Эрх худалдаж авах
            </Button>
          </Box>
          {userListings.length === 0 ? (
            <Typography variant="body1">Одоогоор оруулсан зар байхгүй байна.</Typography>
          ) : (
            <Grid container spacing={3}>
              {userListings.map((car) => (
                <Grid item xs={12} md={6} key={car.id}>
                  <Link to={`/car/${car.id}`} className="car-link">
                    <Card className="car-card">
                      <CardContent>
                        <Typography variant="h6" component="div" className="car-title">
                          {car.yearMade} {car.make} {car.model}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          ({getTimeSincePosted(car.datePosted)})
                        </Typography>
                        <Typography variant="h5" className="car-price">
                          ₮{car.price ? car.price.toLocaleString() : 'N/A'}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" className="car-details">
                          {car.odometer} км | {car.engine} | {car.gearbox} | {car.fuelType}
                        </Typography>
                        <Box className="dealer-info">
                          <Typography variant="body2">{car.views || 0} үзсэн • {car.saves || 0} хадгалсан</Typography>
                        </Box>
                      </CardContent>
                    </Card>
                  </Link>
                </Grid>
              ))}
            </Grid>
          )}
          {userInfo && userInfo.available_listing_slots > 0 && (
            <Box mt={2}>
              <Link to="/sell-my-car" className="list-car-link">
                <button className="profile-button list-car-button">Зар нийтлэх</button>
              </Link>
            </Box>
          )}
        </Container>
      </div>

      {/* PurchaseSlots Dialog */}
      <PurchaseSlots 
        open={showPurchaseSlots} 
        onClose={handleClosePurchaseSlots} 
        onPurchase={handleSuccessfulPurchase} 
        onInitiatePayment={handleInitiatePayment}
        paymentMethod={paymentMethod} // Pass payment method
        setPaymentMethod={setPaymentMethod} // Pass setter
      />

      {/* Payment Dialog */}
      <Dialog 
        open={showQRCodeDialog} 
        onClose={() => {
          setShowQRCodeDialog(false);
          setPaymentStatus(null);
        }}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Төлбөр хийх</DialogTitle>
        <DialogContent>
          {paymentMethod === 'qpay' ? (
            <>
              <DialogContentText>
                Та QR кодыг уншуулж эсвэл богино холбоосыг ашиглан төлбөрөө хийж болно.
              </DialogContentText>
              {!isMobileDevice() && qrCodeData && (
                <Box sx={{ textAlign: 'center', mt: 2 }}>
                  <img 
                    src={`data:image/png;base64,${qrCodeData}`} 
                    alt="QR Code" 
                    style={{ width: '200px', height: '200px' }} 
                  />
                </Box>
              )}
              {shortUrl && (
                <Typography variant="body1" sx={{ mt: 2 }}>
                  Богино холбоос: <a href={shortUrl} target="_blank" rel="noopener noreferrer">{shortUrl}</a>
                </Typography>
              )}
            </>
          ) : paymentMethod === 'khanbank' ? (
            <>
              <DialogContentText>
                Төлбөр хийхийн тулд дараах холбоос дээр дарна уу.
              </DialogContentText>
              {khanBankUrl && (
                <Box sx={{ textAlign: 'center', mt: 2 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    href={khanBankUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Төлбөрийн хуудсыг нээх
                  </Button>
                </Box>
              )}
            </>
          ) : null}
          {isPolling && (
            <Typography variant="body2" sx={{ mt: 2 }}>
              Төлбөрийн статусыг шалгаж байна...
            </Typography>
          )}
          {paymentStatus === 'completed' && (
            <Alert severity="success" sx={{ mt: 2 }}>
              Төлбөр амжилттай хийгдлээ! Таны зарын эрх нэмэгдлээ.
            </Alert>
          )}
          {paymentStatus === 'failed' && (
            <Alert severity="error" sx={{ mt: 2 }}>
              Төлбөр амжилтгүй боллоо. Дахин оролдоно уу.
            </Alert>
          )}
          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => {
              setShowQRCodeDialog(false);
              setPaymentStatus(null);
            }} 
            variant="outlined"
          >
            Хаах
          </Button>
          <Button 
            onClick={handleCheckPaymentStatus} 
            color="primary" 
            disabled={isPolling || paymentStatus === 'completed'}
            variant="contained"
          >
            Төлбөрийн Статусыг Шалгах
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ProfilePage;
