// src/components/BuyerRequests.js

import React, { useState, useEffect } from 'react';
import { getBuyerRequests, createOrGetChat, deleteBuyerRequest } from '../utils/db';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { 
  Typography, Box, Card, CardContent, Grid, CircularProgress, 
  Chip, Avatar, Button, TextField, InputAdornment, Fade, Grow,
  Collapse, IconButton, Table, TableBody, TableCell, TableContainer, 
  TableRow, Paper, CardActions, CardHeader
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { motion, AnimatePresence } from 'framer-motion';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import LocalShippingIcon from '@mui/icons-material/LocalShipping'; // Pickup Truck
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle'; // Van
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled'; // SUV
import ElectricCarIcon from '@mui/icons-material/ElectricCar';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import SpeedIcon from '@mui/icons-material/Speed';
import BoltIcon from '@mui/icons-material/Bolt';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import SearchIcon from '@mui/icons-material/Search';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import EventIcon from '@mui/icons-material/Event';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getTimeSincePosted } from '../utils/timeUtils';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0052FF',
    },
    secondary: {
      main: '#FFA500',
    },
    background: {
      default: '#F5F7FF',
    },
  },
  typography: {
    fontFamily: 'Rubik, Arial, sans-serif',
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '16px',
          boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
          transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
          '&:hover': {
            transform: 'translateY(-5px)',
            boxShadow: '0 6px 25px rgba(0,0,0,0.15)',
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          fontWeight: 600,
        },
      },
    },
  },
});

const getBodyTypeIcon = (bodyType) => {
  switch (bodyType?.toLowerCase()) {
    case 'sedan':
      return <DirectionsCarIcon sx={{ transform: 'scaleX(-1)' }} />;
    case 'pickup truck':
    case 'truck':
      return <LocalShippingIcon sx={{ transform: 'scaleX(-1)' }} />;
    case 'van':
      return <AirportShuttleIcon sx={{ transform: 'scaleX(-1)' }} />;
    case 'suv':
      return <DirectionsCarFilledIcon sx={{ transform: 'scaleX(-1)' }} />;
    case 'hatchback':
      return <ElectricCarIcon sx={{ transform: 'scaleX(-1)' }} />;
    default:
      return <DirectionsCarIcon sx={{ transform: 'scaleX(-1)' }} />;
  }
};

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  '& .MuiCardHeader-root': {
    background: 'linear-gradient(45deg, #0052FF 30%, #00A3FF 90%)',
    color: 'white',
  },
}));

const AnimatedIcon = styled(motion.div)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '48px',
  color: 'white',
  marginBottom: '20px',
  '& > svg': {
    fontSize: '3rem', // Adjust this value as needed
  },
});

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const BuyerRequests = () => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedId, setExpandedId] = useState(null);
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    fetchRequests();
  }, []);

  const fetchRequests = async () => {
    try {
      const data = await getBuyerRequests();
      console.log('Fetched buyer requests:', data);
      if (data && data.results && Array.isArray(data.results)) {
        setRequests(data.results.filter(request => request != null));
      } else {
        throw new Error('Unexpected data structure');
      }
      setLoading(false);
    } catch (err) {
      console.error('Error fetching buyer requests:', err);
      setError('Failed to fetch buyer requests: ' + err.message);
      setLoading(false);
    }
  };

  const handleContactBuyer = (requestId) => {
    setExpandedId(expandedId === requestId ? null : requestId);
  };

  const handleStartChat = async (request) => {
    if (!currentUser) {
      navigate('/login');
      return;
    }

    try {
      const chat = await createOrGetChat(request.user);
      navigate('/chat', { state: { selectedChat: chat } });
    } catch (error) {
      console.error('Error creating or getting chat:', error);
      setError('Failed to initiate chat. Please try again later.');
    }
  };

  const handleDeleteRequest = async (requestId) => {
    if (window.confirm('Are you sure you want to delete this request?')) {
      try {
        await deleteBuyerRequest(requestId);
        setRequests(requests.filter(req => req.id !== requestId));
      } catch (error) {
        console.error('Error deleting request:', error);
        setError('Failed to delete request. Please try again later.');
      }
    }
  };


  const handleExpandClick = (id) => {
    setExpandedId(expandedId === id ? null : id);
  };

  const formatPrice = (price) => {
    if (price >= 1000000) {
      return `${(price / 1000000).toFixed(1)}сая`;
    }
    return price.toLocaleString();
  };


  
  const filteredRequests = requests.filter(request =>
    (request.make && request.make.toLowerCase().includes(searchTerm.toLowerCase())) ||
    (request.model && request.model.toLowerCase().includes(searchTerm.toLowerCase())) ||
    (request.bodyType && request.bodyType.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ maxWidth: 1200, margin: '0 auto', padding: 3 }}>
        <Typography variant="h4" gutterBottom align="center" sx={{ marginBottom: 4, fontWeight: 700 }}>
          Машин Авах Зарууд
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Үйлдвэрлэгч, загвар болон төрлөөр хайна уу"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{ marginBottom: 4 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        {filteredRequests.length === 0 ? (
          <Typography align="center">Тохирсон Хайлт Олдсонгүй.</Typography>
        ) : (
          <Grid container spacing={3} alignItems="flex-start">
            <AnimatePresence>
              {filteredRequests.map((request) => (
                <Grow key={request.id} in={true} timeout={300}>
                  <Grid item xs={12} sm={6} md={4} style={{ display: 'flex' }}>
                    <StyledCard sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                      <CardHeader
                        sx={{
                          height: '140px',
                          padding: 2,
                          '& .MuiCardHeader-content': {
                            overflow: 'hidden',
                          },
                          position: 'relative',
                        }}
                        avatar={
                          <Box sx={{ 
                            display: 'flex', 
                            flexDirection: 'column', 
                            alignItems: 'center', 
                            height: '70px',
                            justifyContent: 'flex-start'
                          }}>
                            <AnimatedIcon
                              initial={{ rotate: -90 }}
                              animate={{ rotate: 0 }}
                              transition={{ duration: 0.5 }}
                              style={{ marginBottom: '4px' }}
                            >
                              {getBodyTypeIcon(request.bodyType)}
                            </AnimatedIcon>
                            {request.fuelType?.toLowerCase() === 'electric' && (
                              <BoltIcon sx={{ color: 'white', fontSize: '1rem' }} />
                            )}
                          </Box>
                        }
                        action={
                          <ExpandMore
                            expand={expandedId === request.id}
                            onClick={() => handleExpandClick(request.id)}
                            aria-expanded={expandedId === request.id}
                            aria-label="show more"
                          >
                            <ExpandMoreIcon />
                          </ExpandMore>
                        }
                        title={
                          <Typography variant="h6" style={{ color: 'white' }}>
                            {request.make && request.model 
                              ? `${request.make} ${request.model} авна`
                              : `${request.bodyType || 'Any Type'} сонирхож байна`}
                          </Typography>
                        }
                        subheader={
                          <Box>
                            {request.exampleCars && request.exampleCars.length > 0 && (
                              <Typography variant="subtitle2" style={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                                Жишээ: {request.exampleCars.join(', ')}
                              </Typography>
                            )}
                            <Typography 
                              variant="caption" 
                              style={{ 
                                color: 'rgba(255, 255, 255, 0.7)', 
                                position: 'absolute', 
                                bottom: 8, 
                                right: 8 
                              }}
                            >
                              {getTimeSincePosted(request.datePosted)}
                            </Typography>
                          </Box>
                        }
                      />
                      <CardContent sx={{ flexGrow: 1 }}>
                        <Box display="flex" flexWrap="wrap" gap={1} mb={2}>
                          <Chip
                            icon={<AttachMoneyIcon />}
                            label={`${formatPrice(request.priceMin)} - ${formatPrice(request.priceMax)}`}
                            color="primary"
                            variant="outlined"
                          />
                          {request.odometerMax && (
                            <Chip
                              icon={<SpeedIcon />}
                              label={`Гүйлт ${request.odometerMax} км бага`}
                              color="primary"
                              variant="outlined"
                            />
                          )}
                          {request.yearMadeMin && request.yearMadeMax && (
                            <Chip
                              label={`Үйлдвэрлэсэн: ${request.yearMadeMin}-${request.yearMadeMax}`}
                              color="primary"
                              variant="outlined"
                            />
                          )}
                          {request.yearImportedMin && request.yearImportedMax && (
                            <Chip
                              label={`Орж Ирсэн: ${request.yearImportedMin}-${request.yearImportedMax}`}
                              color="primary"
                              variant="outlined"
                            />
                          )}
                        </Box>
                        <Box display="flex" flexWrap="wrap" gap={1}>
                          {request.openToOverseas && (
                            <Chip
                              icon={<FlightTakeoffIcon />}
                              label="Гадаадаас ачигдах машин байж болно"
                              color="secondary"
                              size="small"
                            />
                          )}
                          {request.fuelType && (
                            <Chip
                              label={request.fuelType}
                              color="secondary"
                              size="small"
                            />
                          )}
                          {request.transmission && (
                            <Chip
                              label={request.transmission}
                              color="secondary"
                              size="small"
                            />
                          )}
                          {request.drivetrain && (
                            <Chip
                              label={request.drivetrain}
                              color="secondary"
                              size="small"
                            />
                          )}
                        </Box>
                      </CardContent>
                      <Collapse in={expandedId === request.id} timeout="auto" unmountOnExit>
                        <CardContent>
                          {request.additionalRequirements && (
                            <Box
                              sx={{
                                height: '100px',
                                overflowY: 'auto',
                                overflowX: 'hidden',
                                padding: '8px',
                                border: '2px solid #0052FF',
                                borderRadius: '4px',
                                '&::-webkit-scrollbar': {
                                  width: '8px',
                                },
                                '&::-webkit-scrollbar-track': {
                                  background: '#f1f1f1',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                  background: '#0052FF',
                                  borderRadius: '4px',
                                },
                                '&::-webkit-scrollbar-thumb:hover': {
                                  background: '#003CBB',
                                },
                              }}
                            >
                              <Typography 
                                variant="body2" 
                                color="text.primary"
                                sx={{
                                  wordBreak: 'break-word',
                                  whiteSpace: 'pre-wrap',
                                  fontWeight: 500,
                                }}
                              >
                                {request.additionalRequirements}
                              </Typography>
                            </Box>
                          )}
                        </CardContent>
                      </Collapse>
                      <CardActions disableSpacing>
                        <Button 
                          variant="contained" 
                          fullWidth
                          onClick={() => handleContactBuyer(request.id)}
                          sx={{ 
                            borderRadius: '25px',
                            background: 'linear-gradient(45deg, #0052FF 30%, #00A3FF 90%)',
                            color: 'white',
                            fontWeight: 600,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            position: 'relative', // Add this
                            paddingRight: '8px', // Add space for the icon
                          }}
                        >
                          Зарын Эзэнтэй Холбогдох
                          <IconButton
                            sx={{
                              position: 'absolute', // Position the icon absolutely
                              right: 8, // Align to the right
                              top: '50%', // Center vertically
                              transform: expandedId === request.id ? 'translateY(-50%) rotate(180deg)' : 'translateY(-50%)',
                              transition: (theme) =>
                                theme.transitions.create('transform', {
                                  duration: theme.transitions.duration.shortest,
                                }),
                            }}
                          >
                            <ExpandMoreIcon />
                          </IconButton>
                        </Button>
                      </CardActions>
                      <Collapse in={expandedId === request.id} timeout="auto" unmountOnExit>
                        <CardContent>
                          <Typography variant="body1">Утас: {request.buyerPhone}</Typography>
                          <Button
                            variant="outlined"
                            fullWidth
                            onClick={() => handleStartChat(request)}
                            sx={{ mt: 2 }}
                          >
                            Чат Эхлүүлэх
                          </Button>
                        </CardContent>
                      </Collapse>
                    </StyledCard>
                  </Grid>
                </Grow>
              ))}
            </AnimatePresence>
          </Grid>
        )}
      </Box>
    </ThemeProvider>
  );
};

export default BuyerRequests;